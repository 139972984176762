import axios from 'axios';
import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'reactstrap';
import { ReactComponent as AdyenLogo } from '../../assets/images/icons/AdyenLogo.svg';
import { ReactComponent as LockIcon } from '../../assets/images/icons/Lock.svg';
import AvailabilityWarningModal from '../../components/AvailabilityWarningModal/AvailabilityWarningModal';
import BookingsCart from '../../components/BookingsCart/BookingsCart';
import BookingsCartMobileBar from '../../components/BookingsCartMobileBar/BookingsCartMobileBar';
import EditBookingBox from '../../components/EditBookingBox/EditBookingBox';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import LoadingPaymentModal from '../../components/LoadingPaymentMoodal/LoadingPaymentModal';
import MultipleGuestForm from '../../components/MultipleGuestForm/MultipleGuestForm';
import NoAvailabilityModal from '../../components/NoAvailabilityModal/NoAvailabilityModal';
import PermanentModal from '../../components/PermanentModal/PermanentModal';
import SingleGuestForm from '../../components/SingleGuestForm/SingleGuestForm';
import {
  FORM_STATUS_3DS_CHALLENGE_ACTIVATED,
  FORM_STATUS_API_BOOKING_PROCESSING,
  FORM_STATUS_AWAITING_PAYMENT_RESPONSE,
  FORM_STATUS_UNSUBMITTED,
} from '../../constants';
import { useScreenReady } from '../../context/use-screen-ready';
import { API_DOMAIN, CHINA_VERSION } from '../../globals';
import {
  gtmBeginCheckout,
  gtmBookingStepProgress,
  gtmNewsletterSubscription,
  gtmPageView,
} from '../../gtm/events';
import getDeviceType from '../../helpers/getDeviceType';
import {
  addPageCloseWarning,
  clearPageCloseWarning,
} from '../../helpers/page-close-warning';
import useMessage from '../../hooks/useMessage/useMessage';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import useTotalPrice from '../../hooks/useTotalPrice/useTotalPrice';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import {
  bookingStatuses,
  resetBookingsState,
} from '../../redux/slices/bookingsSlice/bookingsSlice';
import {
  resetGuestFormState,
  setGuestFormValue,
} from '../../redux/slices/guestFormSlice/guestFormSlice';
import { resetPaymentState } from '../../redux/slices/paymentSlice/paymentSlice';
import styles from './GuestForm.module.css';
import ActionTopBar from './components/ActionTopBar/ActionTopBar';
import AgentDetails from './components/AgentDetails/AgentDetails';
import AgentFormFields from './components/AgentFormFields/AgentFormFields';
import GuestFormCheckButtons from './components/GuestFormCheckButtons/GuestFormCheckButtons';
import Payment from './components/Payment/Payment';
import PaymentInformationModal from './components/PaymentInformationModal/PaymentInformationModal';
import RoomPolicies from './components/RoomPolicies/RoomPolicies';

const CREATE_BOOKINGS_URL = `${API_DOMAIN}/create-bookings`;
const EDIT_BOOKING_URL = `${API_DOMAIN}/edit-booking`;

const { UNSUBMITTED } = bookingStatuses;

const GuestForm = () => {
  const { setIsScreenReady } = useScreenReady();
  const {
    t,
    constants: { errorMessage },
  } = useTranslate();

  const dispatch = useDispatch();
  const { isLargeDesktop } = useScreenDetector();
  const navigate = useNavigate();
  const [Message, showMessage, closeMessage] = useMessage();
  const bookings = useSelector((state) => state.bookings.list);
  const hasAgentProfile = useSelector((state) => !!state.bookings.agentProfile);
  const agentId = bookings.find((b) => !!b.agentId)?.agentId;
  const form = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues:
      agentId && !hasAgentProfile ? { agent: { id: agentId } } : {},
  });
  const { totalPrice } = useTotalPrice(bookings);

  const paymentRef = useRef();
  const multipleGuestFormRefs = useRef([]);
  const availabilityWarningModalRef = useRef();

  const [formStatus, setFormStatus] = useState(FORM_STATUS_UNSUBMITTED);
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const [openPaymentInformationModal, setOpenPaymentInformationModal] =
    useState(false);
  const [scrollToErrors, setScrollToErrors] = useState(false);
  const [paymentOption, setPaymentOption] = useState('payNow');
  const [threedsModalOpen, setThreedsModalOpen] = useState(false);

  const payment = useSelector((state) => state.payment);
  const countryCode = useSelector((state) => state.appSettings.countryCode);
  const accessToken = useSelector((state) => state.user.accessToken);
  const guestProfile = useSelector((state) => state.user.profile);
  const hotel = useSelector(
    (state) => state.belmond.hotels[bookings[0].productCode]
  );
  const bookingsGuestFormValues = useSelector(
    (state) => state.guestForm.values
  );
  const extendedVersion = useSelector(
    (state) => state.appSettings.extendedVersion
  );
  const currency = useSelector((state) => state.appSettings.currencies.current);
  const currentBooking = useSelector((state) =>
    state.bookings.list.find((b) => b.id === state.bookings.current)
  );
  const confirmedBookingEdited = bookings.some((b) => b.status !== UNSUBMITTED);
  // deconstruct to avoid having to use form as a dependency is react hooks
  const { setValue, getValues, formState, watch, trigger } = form;
  multipleGuestFormRefs.current = bookings.reduce((prev, curr) => {
    const id = `${curr.id}-${curr.productCode}`;
    return { ...prev, [id]: multipleGuestFormRefs.current[id] ?? createRef() };
  }, {});

  const payNowData = useMemo(() => {
    if (hotel.prePaymentEnabled && paymentOption === 'payNow') {
      const formValues = getValues();
      return {
        bookings: bookings.map(({ roomType, roomRate, ...booking }) => ({
          ...booking,
          ratePlanCode: roomRate.code,
          roomTypeCode: roomType.code,
        })),
        newAgent: formValues.agent,
        agentId: !formValues.agent ? agentId : null,
        agentCrmId: bookings.find((b) => !!b.agentCrmId)?.agentCrmId,
      };
    }
  }, [paymentOption, getValues, bookings, agentId, hotel.prePaymentEnabled]);

  const submitButtonText = useCallback(() => {
    switch (true) {
      case confirmedBookingEdited:
        return t('Update Booking');
      case CHINA_VERSION:
        return t('Pre-order');
      case hotel.prePaymentEnabled && paymentOption === 'payNow':
        return `Pay ${totalPrice} & Confirm My Booking`;
      default:
        return t('Confirm My Booking');
    }
  }, [
    t,
    confirmedBookingEdited,
    hotel.prePaymentEnabled,
    paymentOption,
    totalPrice,
  ]);

  const focusFirstErrorField = useCallback(() => {
    const { agent, bookingsGuestValues, conditionsCheck, chinaGuaranteeCheck } =
      formState.errors;
    let errorElement;

    const bookingsGuestValuesKeys = Object.keys(bookingsGuestValues ?? {});
    const agentKeys = Object.keys(agent ?? {});

    if (agentKeys.length > 0) {
      errorElement = document.getElementById(`agent.${agentKeys[0]}ID`);
    } else if (bookingsGuestValuesKeys.length > 0) {
      const booking = bookings.find((b) =>
        bookingsGuestValuesKeys.includes(`${b.id}-${b.productCode}`)
      );
      const guestValuesKey = `${booking.id}-${booking.productCode}`;
      let [errorKey] = Object.keys(bookingsGuestValues[guestValuesKey]);
      multipleGuestFormRefs.current[guestValuesKey].current?.setFormExpanded?.(
        true
      );

      if (
        typeof bookingsGuestValues[guestValuesKey][errorKey].ref?.focus !==
        'function'
      ) {
        errorKey = `${errorKey}.${
          Object.keys(bookingsGuestValues[guestValuesKey][errorKey])[0]
        }`;
      }
      errorElement = document.getElementById(
        `bookingsGuestValues[${guestValuesKey}].${errorKey}ID`
      );
    } else if (conditionsCheck?.type) {
      errorElement = document.getElementById('conditionsCheck');
    } else if (chinaGuaranteeCheck?.type) {
      errorElement = document.getElementById('chinaGuaranteeCheck');
    }
    if (errorElement) {
      setTimeout(() => {
        //We needed to split focus and scroll in two functions because .focus() alone would not scroll into view on iOS devices
        errorElement.focus({ preventScroll: true });
        errorElement.scrollIntoView({ block: 'center', behaivor: 'smooth' });
      }, 10);
    }
  }, [formState.errors, bookings]);

  const fillFormValues = useCallback(
    (formId, formFields, values, path = []) => {
      const formFieldsKeys = Object.keys(formFields);
      formFieldsKeys.forEach((name) => {
        if (values[name] && formFields[name] !== undefined) {
          if (typeof formFields[name] === 'object') {
            fillFormValues(formId, formFields[name], values[name], [
              ...path,
              name,
            ]);
          } else {
            setValue(
              `bookingsGuestValues[${formId}]${
                path.length ? `.${path.join('.')}` : ''
              }.${name}`,
              name === 'phone'
                ? values[name]
                : values[name].replace(/^\+?0+/, '')
            );
          }
        }
      });
    },
    [setValue]
  );

  const fillFormValuesWithGuestProfile = useCallback(() => {
    const formValues = getValues();
    const formId = `${bookings[0].id}-${bookings[0].productCode}`;
    const formFields = formValues.bookingsGuestValues[formId];
    const isCrmAgent = bookings.find((b) => !!b.agentCrmId);

    if (isCrmAgent) {
      delete formFields.firstName;
      delete formFields.lastName;
      delete formFields.email;
      delete formFields.phone;
    }

    fillFormValues(formId, formFields, guestProfile);
  }, [guestProfile, bookings, getValues, fillFormValues]);

  const editConfirmedBookings = useCallback(async () => {
    const formValues = getValues();
    const booking = bookings[0];
    const { secondaryGuests, ...guest } =
      formValues.bookingsGuestValues[`${booking.id}-${booking.productCode}`];

    const body = {
      encryptedEmail: booking.encryptedEmail,
      newAgent: formValues.agent,
      agentId: !formValues.agent ? booking?.agentId : null,
      agentCrmId: booking?.agentCrmId,
      bookingId: booking.id,
      roomTypeCode: booking.roomType.code,
      ratePlanCode: booking.roomRate.code,
      productCode: booking.productCode,
      checkIn: booking.startDate,
      checkOut: booking.endDate,
      numAdults: booking.numAdults,
      children: booking.children,
      guest,
      secondaryGuests,
      specialCodeType: booking.specialCodeType,
      specialCodeValue: booking.specialCodeValue,
      chinaVersion: CHINA_VERSION,
      deviceType: getDeviceType(),
      isMarketingOptin: formValues.isMarketingOptin,
      currencyCode: booking.roomRate.currencyCode,
    };

    try {
      addPageCloseWarning();
      const res = await axios.post(EDIT_BOOKING_URL, body);

      if (formValues.isMarketingOptin) {
        gtmNewsletterSubscription('Payment Detail');
      }

      // allow navigation to happen first
      setTimeout(() => {
        dispatch(resetGuestFormState());
        dispatch(resetBookingsState());
        dispatch(resetPaymentState());
      }, 100);

      const { bookingId, productCode, encryptedEmail } = res.data;

      navigate(
        `/confirmation?bookingId=${bookingId}&productCode=${productCode}&email=${encryptedEmail}`,
        { replace: true, state: { checkoutReferral: true } }
      );
    } catch (e) {
      if (e.response?.data.error === 'NO_AVAILABILITY') {
        setShowAvailabilityModal(true);
      } else {
        showMessage(errorMessage, 'danger');
      }
    } finally {
      clearPageCloseWarning();
      setFormStatus(FORM_STATUS_UNSUBMITTED);
    }
  }, [getValues, bookings, navigate, showMessage, dispatch, errorMessage]);

  const createBookings = useCallback(
    async (paymentBrand) => {
      const formValues = getValues();
      const body = {
        shopperReference: payment?.session?.shopperReference,
        orderReference: payment?.session?.reference,
        bookings: [],
        isMarketingOptin: formValues.isMarketingOptin,
        chinaVersion: CHINA_VERSION,
        deviceType: getDeviceType(),
        newAgent: formValues.agent,
        agentId: !formValues.agent ? agentId : null,
        agentCrmId: bookings.find((b) => !!b.agentCrmId)?.agentCrmId,
      };

      body.bookings = bookings.map((booking) => {
        const { secondaryGuests, ...guest } =
          formValues.bookingsGuestValues[
            `${booking.id}-${booking.productCode}`
          ];

        return {
          bookingId: booking.id,
          roomTypeCode: booking.roomType.code,
          ratePlanCode: booking.roomRate.code,
          productCode: booking.productCode,
          checkIn: booking.startDate,
          checkOut: booking.endDate,
          numAdults: booking.numAdults,
          children: booking.children,
          guest,
          secondaryGuests,
          specialCodeType: booking.specialCodeType,
          specialCodeValue: booking.specialCodeValue,
          currencyCode: booking.roomRate.currencyCode,
        };
      });

      try {
        addPageCloseWarning();
        const { data } = await axios.post(CREATE_BOOKINGS_URL, body, {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
          },
        });

        // save gtm purchase event params for confirmation page
        localStorage.setItem(
          `gtmPurchaseEventParams`,
          JSON.stringify({
            bookings,
            paymentBrand,
            orderReference: payment?.session?.reference,
            hotel,
          })
        );

        if (formValues.isMarketingOptin) {
          gtmNewsletterSubscription('Payment Detail');
        }

        if (extendedVersion) {
          navigate('/restaurant', { replace: true });
        } else {
          // allow the navigation to happen first
          setTimeout(() => {
            dispatch(resetGuestFormState());
            dispatch(resetBookingsState());
            dispatch(resetPaymentState());
          }, 100);

          const { bookingId, productCode, encryptedEmail } = data.bookings[0];

          navigate(
            `/confirmation?bookingId=${bookingId}&productCode=${productCode}&email=${encryptedEmail}`,
            {
              replace: true,
              state: {
                checkoutReferral: true,
                failedBookings: data.failedBookings,
              },
            }
          );
        }
      } catch (e) {
        if (e.response?.data.error === 'NO_AVAILABILITY') {
          setShowAvailabilityModal(true);
        } else {
          showMessage(errorMessage, 'danger');
        }
        paymentRef.current?.reset();
      } finally {
        clearPageCloseWarning();
        setFormStatus(FORM_STATUS_UNSUBMITTED);
      }
    },
    [
      getValues,
      payment?.session?.shopperReference,
      payment?.session?.reference,
      agentId,
      bookings,
      accessToken,
      hotel,
      extendedVersion,
      navigate,
      dispatch,
      showMessage,
      errorMessage,
    ]
  );

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  const handleMultipleGuestFormCopyGuestValuesBtnClick = () => {
    const fieldNames = [
      'prefix',
      'firstName',
      'lastName',
      'phone',
      'email',
      'address.address',
      'address.postalCode',
      'address.city',
      'address.countryCode',
      'address.state',
      'notes',
    ];

    bookings.slice(1).forEach((_, i) =>
      fieldNames.forEach((fieldName) => {
        setValue(
          `bookingsGuestValues[${bookings[i + 1].id}-${
            bookings[i + 1].productCode
          }].${fieldName}`,
          getValues(
            `bookingsGuestValues[${bookings[0].id}-${bookings[0].productCode}].${fieldName}`
          ),
          {
            shouldValidate: true,
          }
        );
      })
    );
  };

  const handleGuestFormButtonClick = useCallback(async () => {
    closeMessage();

    const isFormValid = await trigger();
    if (!isFormValid) {
      setScrollToErrors(true);
    } else if (confirmedBookingEdited) {
      setFormStatus(FORM_STATUS_API_BOOKING_PROCESSING);
      await editConfirmedBookings();
    } else if (CHINA_VERSION) {
      setFormStatus(FORM_STATUS_API_BOOKING_PROCESSING);
      createBookings();
    } else {
      const isPaymentFormValid = paymentRef.current?.submit();
      if (isPaymentFormValid) {
        setFormStatus(FORM_STATUS_AWAITING_PAYMENT_RESPONSE);
      }
    }
  }, [
    closeMessage,
    trigger,
    confirmedBookingEdited,
    editConfirmedBookings,
    createBookings,
  ]);

  const handlePaymentSuccess = useCallback(
    ({ paymentBrand }) => {
      setFormStatus(FORM_STATUS_API_BOOKING_PROCESSING);
      createBookings(paymentBrand);
    },
    [createBookings]
  );

  const handlePaymentFailure = useCallback(() => {
    setThreedsModalOpen(false);
    setFormStatus(FORM_STATUS_UNSUBMITTED);
    paymentRef.current?.reset();
  }, []);

  const handlePayment3dsAuth = useCallback(() => {
    setThreedsModalOpen(true);
    setFormStatus(FORM_STATUS_3DS_CHALLENGE_ACTIVATED);
  }, []);

  const handlePaymentSubmit3dsCode = useCallback(() => {
    setThreedsModalOpen(false);
    setFormStatus(FORM_STATUS_API_BOOKING_PROCESSING);
  }, []);

  const handlePaymentCreateSessionFailure = useCallback((e) => {
    if (e.response?.data.error === 'NO_AVAILABILITY') {
      availabilityWarningModalRef.current?.checkAvailability();
    }
  }, []);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (typeof getValues(name) === 'string') {
        dispatch(setGuestFormValue({ name, value: getValues(name) }));
      }
    });

    if (!formState.isDirty) {
      // we check if we have stored a value from any of our current bookings
      if (
        bookingsGuestFormValues.some(({ name }) =>
          bookings.find((b) =>
            name.startsWith(`bookingsGuestValues[${b.id}-${b.productCode}]`)
          )
        )
      ) {
        bookingsGuestFormValues.forEach(({ name, value }) =>
          setValue(name, value)
        );
      } else if (guestProfile) {
        fillFormValuesWithGuestProfile();
      }
    }

    return () => subscription.unsubscribe();
  }, [
    bookingsGuestFormValues,
    bookings,
    dispatch,
    fillFormValuesWithGuestProfile,
    formState.isDirty,
    getValues,
    setValue,
    watch,
    guestProfile,
  ]);

  useEffect(() => {
    if (scrollToErrors) {
      focusFirstErrorField();
      setScrollToErrors(false);
    }
  }, [focusFirstErrorField, formState.errors, scrollToErrors]);

  // GTM
  useEffect(() => {
    if (hotel?.address && bookings && currency) {
      gtmPageView(hotel);
      gtmBeginCheckout(bookings, hotel);
      gtmBookingStepProgress('Details');
      setIsScreenReady(true);
    }
  }, [bookings, currency, hotel, setIsScreenReady]);

  useEffect(() => {
    document.title = t('Complete Your Details');
  }, [t]);

  return (
    <>
      <PaymentInformationModal
        onClose={() => setOpenPaymentInformationModal(false)}
        open={openPaymentInformationModal}
      />
      <AvailabilityWarningModal
        ref={availabilityWarningModalRef}
        formStatus={formStatus}
      />
      <LoadingPaymentModal
        open={formStatus === FORM_STATUS_API_BOOKING_PROCESSING}
      />
      {!isLargeDesktop && (
        <BookingsCartMobileBar
          status="CHECK_OUT"
          bookings={bookings}
          disabled={formStatus !== FORM_STATUS_UNSUBMITTED}
        />
      )}
      <NoAvailabilityModal open={showAvailabilityModal} />
      <ActionTopBar
        bookingId={bookings[0].id}
        productCode={bookings[0].productCode}
      />
      <div className="container-xxl">
        <Row className="d-flex flex-row-reverse gx-4">
          {isLargeDesktop && (
            <Col xl="4">
              <div className="d-block sticky-top py-4">
                <BookingsCart status="CHECK_OUT" bookings={bookings} />
              </div>
            </Col>
          )}
          <Col xl="8 py-4">
            {confirmedBookingEdited && (
              <EditBookingBox currentBooking={currentBooking} />
            )}
            <FormProvider {...form}>
              <Form>
                {hasAgentProfile && <AgentDetails />}
                {!confirmedBookingEdited && agentId && !hasAgentProfile && (
                  <AgentFormFields
                    disabled={formStatus !== FORM_STATUS_UNSUBMITTED}
                  />
                )}
                {bookings.length > 1 ? (
                  bookings.map((booking, i) => (
                    <div
                      className="mx-3"
                      key={`${booking.id}-${booking.productCode}`}
                    >
                      <MultipleGuestForm
                        key={`${booking.id}-${booking.productCode}`}
                        disabled={formStatus !== FORM_STATUS_UNSUBMITTED}
                        numAdults={booking.numAdults}
                        guestFormIndex={i}
                        onCopyGuestValuesBtnClick={
                          !i
                            ? handleMultipleGuestFormCopyGuestValuesBtnClick
                            : null
                        }
                        booking={booking}
                        ref={
                          multipleGuestFormRefs.current[
                            `${booking.id}-${booking.productCode}`
                          ]
                        }
                        guestFormsLength={bookings.length}
                        defaultExpanded={!i}
                      />
                    </div>
                  ))
                ) : (
                  <Row className="mx-1">
                    <SingleGuestForm
                      guestFormKey={`${bookings[0].id}-${bookings[0].productCode}`}
                      disabled={formStatus !== FORM_STATUS_UNSUBMITTED}
                      numAdults={bookings[0].numAdults}
                    />
                  </Row>
                )}
                <Row className="mt-5 mx-1">
                  <div>
                    <h2>{t('Policies')}</h2>
                    {bookings.map((booking, i) => (
                      <RoomPolicies
                        key={booking.id}
                        booking={booking}
                        showCommission={hasAgentProfile && booking?.agentCrmId}
                      />
                    ))}
                  </div>
                </Row>
                <GuestFormCheckButtons
                  disabled={formStatus !== FORM_STATUS_UNSUBMITTED}
                  defaultMarketingOptinChecked={bookings[0].isMarketingOptin}
                />
                <Row className="mt-4 mx-1">
                  {!confirmedBookingEdited && (
                    <div className={styles.GuestForm__paymentInformation}>
                      <h2>
                        {CHINA_VERSION
                          ? t('Pre-order Booking')
                          : t('Payment information')}
                      </h2>
                      <Button
                        className="button transparent"
                        type="button"
                        onClick={() => setOpenPaymentInformationModal(true)}
                      >
                        {t('Why do we ask for this?')}
                      </Button>
                    </div>
                  )}
                  <Message />
                  {!confirmedBookingEdited && !CHINA_VERSION && (
                    <>
                      {hotel.prePaymentEnabled && (
                        <div className="d-flex align-items-center mb-3">
                          <input
                            type="radio"
                            id="payNow"
                            name="paymentOption"
                            value="payNow"
                            checked={paymentOption === 'payNow'}
                            onChange={handlePaymentOptionChange}
                          />
                          <label htmlFor="payNow" className="ms-2">
                            Pay Now
                          </label>
                          <input
                            type="radio"
                            id="payAtHotel"
                            name="paymentOption"
                            value="payAtHotel"
                            checked={paymentOption === 'payAtHotel'}
                            onChange={handlePaymentOptionChange}
                            className="ms-4"
                          />
                          <label htmlFor="payAtHotel" className="ms-2">
                            Pay at the hotel
                          </label>
                        </div>
                      )}
                      <PermanentModal isModal={threedsModalOpen}>
                        {threedsModalOpen && (
                          <h2>{t('Secure authentication')}</h2>
                        )}
                        <Payment
                          ref={paymentRef}
                          onSuccess={handlePaymentSuccess}
                          onFailure={handlePaymentFailure}
                          on3dsAuth={handlePayment3dsAuth}
                          onSubmit3dsCode={handlePaymentSubmit3dsCode}
                          onCreateSessionFailure={
                            handlePaymentCreateSessionFailure
                          }
                          payNowData={payNowData}
                          productCode={bookings[0].productCode}
                          countryCode={
                            countryCode ||
                            getValues(
                              `bookingsGuestValues[${bookings[0].id}-${bookings[0].productCode}].address.countryCode`
                            )
                          }
                          // onSessionCreated={() => setIsSubmitting(false)}
                        />
                      </PermanentModal>
                    </>
                  )}

                  <div className={styles.GuestForm__buttonPadding}>
                    {[
                      FORM_STATUS_UNSUBMITTED,
                      FORM_STATUS_AWAITING_PAYMENT_RESPONSE,
                    ].includes(formStatus) && (
                      <LoadingButton
                        ariaLabel="CONFIRM MY BOOKING"
                        loading={
                          formStatus === FORM_STATUS_AWAITING_PAYMENT_RESPONSE
                        }
                        onClick={handleGuestFormButtonClick}
                        className="button my-3 w-100"
                        type="button"
                        disabled={
                          formStatus === FORM_STATUS_AWAITING_PAYMENT_RESPONSE
                        }
                      >
                        <LockIcon
                          style={{ marginRight: 10, marginBottom: 5 }}
                        />
                        {submitButtonText()}
                      </LoadingButton>
                    )}
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <span>{t('Powered by')}</span>
                    <a
                      href="https://www.adyen.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AdyenLogo style={{ marginLeft: 20 }} />
                    </a>
                  </div>
                </Row>
              </Form>
            </FormProvider>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GuestForm;
