import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CURRENCY_SYMBOLS } from '../../constants';

const usePrintPrice = (productCode = null) => {
  const { exchanges, current: selectedCurrency } = useSelector(
    (state) => state.appSettings.currencies
  );
  const hotels = useSelector((state) => state.belmond.hotels);
  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const renderPrice = useCallback(
    (
      priceToPrint,
      numOfDecimals,
      fromCurrencyMid,
      baseCurrency,
      toCurrency,
      formatOnly
    ) => {
      const symbol =
        CURRENCY_SYMBOLS[
          formatOnly ? baseCurrency : toCurrency || selectedCurrency
        ]?.symbol;

      const exchangeRate = formatOnly
        ? 1
        : exchanges[toCurrency || selectedCurrency] / fromCurrencyMid;

      return `${symbol}${new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: numOfDecimals,
        minimumFractionDigits: numOfDecimals,
      }).format(exchangeRate * Number(priceToPrint))}`;
    },
    [selectedCurrency, exchanges]
  );

  const renderPriceAsNumber = useCallback(
    (priceToPrint, fromCurrencyMid, toCurrency, formatOnly) => {
      const exchangeRate = formatOnly
        ? 1
        : exchanges[toCurrency || selectedCurrency] / fromCurrencyMid;
      return exchangeRate * Number(priceToPrint);
    },
    [selectedCurrency, exchanges]
  );

  const handleDifferentTaxRules = useCallback(
    (
      value,
      numOfDecimals,
      hotel,
      baseCurrency,
      fromCurrencyMid,
      formatOnly
    ) => {
      const isCaliforniaTax = hotel?.californiaTax && countryCode === 'US';

      const isExlusiveTaxesAndFees =
        hotel?.bbeExclusiveTax && ['MX', 'US'].includes(countryCode);

      // california tax takes priority
      let price;
      switch (true) {
        case isCaliforniaTax:
          price = value.valueIncludingServiceCharge;
          break;
        case isExlusiveTaxesAndFees:
          price = value.valueExcludingTaxesAndFees;
          break;
        default:
          price = value.value;
      }

      return {
        price: renderPrice(
          price,
          numOfDecimals,
          fromCurrencyMid,
          baseCurrency,
          value.toCurrency,
          formatOnly
        ),
        numericPrice: renderPriceAsNumber(
          price,
          fromCurrencyMid,
          value.toCurrency,
          formatOnly
        ),
        numericPriceWithoutConversion: price,
        isExlusiveTaxesAndFees,
        isIncludingServiceCharge: isCaliforniaTax,
        currencyCode: value.toCurrency || baseCurrency || hotel.currency,
      };
    },
    [countryCode, renderPrice, renderPriceAsNumber]
  );

  const handleObjectPayload = useCallback(
    (value, numOfDecimals) => {
      const hotel = hotels[value.productCode || productCode];
      const baseCurrency = value.baseCurrency || hotel.currency;
      let fromCurrencyMid = exchanges[baseCurrency];

      // we can add a flag if we have a different base currency and want to convert
      // currently, if the base currency is different to the hotel currency
      // then we dont allow conversion

      const formatOnly = !value.toCurrency && baseCurrency !== hotel.currency;

      const differentTaxRules =
        value.valueExcludingTaxesAndFees || value.valueIncludingServiceCharge;

      if (differentTaxRules) {
        return handleDifferentTaxRules(
          value,
          numOfDecimals,
          hotel,
          baseCurrency,
          fromCurrencyMid,
          formatOnly
        );
      }

      // normal tax rules
      return {
        numericPrice: renderPriceAsNumber(
          value.value,
          fromCurrencyMid,
          value.toCurrency,
          formatOnly
        ),
        currencyCode: value.toCurrency || value.baseCurrency || hotel.currency,
        price: renderPrice(
          value.value,
          numOfDecimals,
          fromCurrencyMid,
          baseCurrency,
          value.toCurrency,
          formatOnly
        ),
      };
    },
    [
      exchanges,
      hotels,
      renderPrice,
      renderPriceAsNumber,
      productCode,
      handleDifferentTaxRules,
    ]
  );

  // we should probably make sure that this returns the same types, currently we return different
  // types and that is a bit confusing, lets improve the interface here

  const printPrice = useCallback(
    (value, rounded = false) => {
      const numOfDecimals = rounded ? 0 : 2;
      if (typeof value === 'object') {
        return handleObjectPayload(value, numOfDecimals);
      } else {
        return renderPrice(
          value,
          numOfDecimals,
          exchanges[hotels[productCode].currency]
        );
      }
    },
    [exchanges, hotels, productCode, renderPrice, handleObjectPayload]
  );

  return {
    printPrice,
  };
};

export default usePrintPrice;
