import moment from 'moment';

const calculateAddonPrice = (addon, booking) => {
  const { numAdults, numChildren = 0, startDate, endDate } = booking;
  const numOfNights = moment(endDate).diff(moment(startDate), 'days');

  const { price, pricingType } = addon;

  switch (pricingType) {
    case 'Per stay':
      return Number(price);
    case 'Per person per night':
      return (numAdults + numChildren) * numOfNights * Number(price);
    case 'Per person':
      return (numAdults + numChildren) * Number(price);
    case 'Per night':
      return numOfNights * Number(price);
    default:
      return Number(price);
  }
};

export default calculateAddonPrice;
