import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ReactComponent as PhoneIcon } from '../../assets/images/icons/Phone.svg';
import Currency from '../../components/Currency/Currency';
import GuestLogin from '../../components/GuestLogin/GuestLogin';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import PasswordReset from '../../components/PasswordReset/PasswordReset';
import SignInForm from '../../components/SignInForm/SignInForm';
import SignUpForm from '../../components/SignUpForm/SignUpForm';
import SlidingMenu from '../../components/SlidingMenu/SlidingMenu';
import { CHINA_VERSION, MY_ACCOUNT_DOMAIN, env } from '../../globals';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import { checkActiveAuthSession } from '../../redux/slices/userSlice/userSlice';
import styles from './Header.module.css';
import MobileMenuItems from './components/MobileMenuItems/MobileMenuItems';

const Header = ({ productCode, phone, hotelCurrency }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLargeDesktop, isTablet } = useScreenDetector();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loginMenuOpen, setLoginMenuOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(true);
  const [passwordResetMenuOpen, setPasswordResetMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logoLink, setLogoLink] = useState(MY_ACCOUNT_DOMAIN);

  const passwordResetParam = searchParams.get('verification_code');
  const emailVerifiedParam = searchParams.get('email_verified');

  const loggedIn = useSelector((state) => !!state.user.accessToken);
  const hotel = useSelector((state) => state.belmond.hotels[productCode]);
  const languagesLoaded = useSelector(
    (state) => state.appSettings.languages.list?.length > 0
  );
  const loginEnabled = useSelector((state) => state.appSettings.loginEnabled);
  const media = useSelector((state) => state.media.mediaUrls);

  const hotelLogoImageSrc = media.find((mediaItem) =>
    mediaItem.includes(`logo_${productCode}`)
  );
  const brandingLogoImageSrc = media.find((mediaItem) =>
    mediaItem.includes(`branding-black-logo`)
  );

  const handleLoginClick = async () => {
    if (!loggedIn && !CHINA_VERSION && loginEnabled) {
      setLoading(true);
      const loginResult = await dispatch(checkActiveAuthSession());

      if (!loginResult) {
        setLoginMenuOpen(true);
        setMobileMenuOpen(false);
      }
      setLoading(false);
    }
  };

  const handlePasswordResetLoginRedirect = () => {
    setPasswordResetMenuOpen(false);
    setLoginMenuOpen(true);
    const params = new URLSearchParams(searchParams);
    params.delete('verification_code');
    params.delete('email');
    setSearchParams(params);
  };

  useEffect(() => {
    if (passwordResetParam) {
      setTimeout(() => {
        setPasswordResetMenuOpen(true);
      }, 100);
    }
  }, [passwordResetParam]);

  useEffect(() => {
    if (emailVerifiedParam) {
      setTimeout(() => {
        setLoginMenuOpen(true);
      }, 100);
    }
  }, [emailVerifiedParam]);

  useEffect(() => {
    if (loggedIn) {
      setLoginMenuOpen(false);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!hotel) setLogoLink(MY_ACCOUNT_DOMAIN);
    else {
      const url = hotel.website?.replace(
        'https://www.belmond.com',
        MY_ACCOUNT_DOMAIN
      );
      setLogoLink(url);
    }
  }, [hotel]);

  return (
    <>
      {!CHINA_VERSION && loginEnabled && (
        <SlidingMenu
          isOpen={loginMenuOpen}
          onClose={() => setLoginMenuOpen(false)}
        >
          {' '}
          <div className="d-flex flex-column  align-items-center overflow-auto">
            {signInOpen ? (
              <SignInForm
                onError={() => setLoginMenuOpen(true)}
                onSignUpBtnClick={() => setSignInOpen(false)}
                emailVerified={!!emailVerifiedParam}
              />
            ) : (
              <SignUpForm
                onError={() => setLoginMenuOpen(true)}
                onSignInBtnClick={() => setSignInOpen(true)}
                productCode={productCode}
              />
            )}
          </div>
        </SlidingMenu>
      )}
      {!CHINA_VERSION && loginEnabled && (
        <SlidingMenu
          isOpen={passwordResetMenuOpen}
          onClose={() => setPasswordResetMenuOpen(false)}
        >
          <PasswordReset onLoginRedirect={handlePasswordResetLoginRedirect} />
        </SlidingMenu>
      )}
      <div className="container-xxl d-flex">
        {!isLargeDesktop && (
          <div className="d-inline">
            <SlidingMenu
              isOpen={mobileMenuOpen}
              onClose={() => setMobileMenuOpen(false)}
              left
              headerContent={
                productCode ? (
                  <img
                    src={hotelLogoImageSrc}
                    alt={`Belmond ${productCode} logo`}
                    className={styles.Header__mobileLogo}
                  />
                ) : (
                  <img
                    src={brandingLogoImageSrc}
                    alt="Belmond logo"
                    style={{ marginTop: 34, marginBottom: 34 }}
                    height={isTablet ? 13 : 18}
                  />
                )
              }
            >
              <MobileMenuItems
                productCode={productCode}
                hotelCurrency={hotelCurrency}
                onClickLogin={handleLoginClick}
              />
            </SlidingMenu>
          </div>
        )}
        <Row
          className={`d-flex justify-content-between align-items-center flex-fill ${styles.Header__headerContainer}`}
        >
          {!isLargeDesktop && (
            <Col
              xs="2"
              xl="4"
              className="d-flex overflow-hidden justify-content-center"
            >
              <button
                className={`${styles.Header__hamburger} h-100`}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                aria-label="Open the mobile menu"
              ></button>
            </Col>
          )}
          {isLargeDesktop && (
            <Col xl="5" className="d-flex">
              <div className="d-flex align-items-center justify-content-start w-100">
                <a
                  href={`tel:${phone}`}
                  title=""
                  className={styles.Header__phoneLink}
                >
                  <PhoneIcon />
                  <div className="mx-1">
                    {phone || (
                      <Skeleton
                        width={150}
                        className="mx-2"
                        containerTestId="phoneSkeleton"
                      />
                    )}
                  </div>
                </a>

                <Currency hotelCurrency={hotelCurrency} />

                {env !== 'prod' && (
                  <div
                    style={{
                      marginLeft: 10,
                      textTransform: 'uppercase',
                      color: 'red',
                    }}
                  >
                    {env.toUpperCase()}
                  </div>
                )}
              </div>
            </Col>
          )}
          <Col xs="8" xl="2" className="text-center">
            <div className={styles.Header__logoPosition}>
              <a
                href={logoLink}
                title=""
                data-testid="logoLink"
                target="_blank"
                rel="noreferrer"
              >
                {productCode ? (
                  <img
                    className={styles.Header__headerLogo}
                    src={hotelLogoImageSrc}
                    alt={`Belmond ${productCode} logo`}
                  />
                ) : (
                  <img
                    height={isTablet ? 13 : 22}
                    src={brandingLogoImageSrc}
                    alt="Belmond logo"
                    style={{ marginTop: 23, marginBottom: 23 }}
                  />
                )}
              </a>
            </div>
          </Col>
          {isLargeDesktop && (
            <Col xl="5" className="d-flex">
              <div className="d-flex w-100  align-items-center justify-content-end">
                <div className="d-flex justify-content-end">
                  {languagesLoaded ? (
                    <LanguageSelector productCode={productCode} />
                  ) : (
                    <Skeleton width={80} containerTestId="language-skeleton" />
                  )}
                </div>
                {!CHINA_VERSION && loginEnabled && (
                  <button
                    onClick={handleLoginClick}
                    aria-label="Log in to your account"
                    type="button"
                    style={{ marginRight: '20px' }}
                  >
                    <GuestLogin loading={loading} productCode={productCode} />
                  </button>
                )}
                <Button
                  className="button transparent bold"
                  onClick={() => navigate('/findreservation')}
                  aria-label="navigate to find reservation"
                >
                  {t('Find Reservation')}
                </Button>
              </div>
            </Col>
          )}
          {!isLargeDesktop && (
            <Col xs="2" className="d-flex justify-content-center">
              <a href={`tel:${phone}`}>
                <PhoneIcon />
              </a>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

Header.propTypes = {
  productCode: PropTypes.string,
  phone: PropTypes.string,
  hotelCurrency: PropTypes.string,
};

export default Header;
