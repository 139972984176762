import { useForm } from 'react-hook-form';
import { Col, Form, Row } from 'reactstrap';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import LoadingButton from '../../../../components/LoadingButton/LoadingButton';
import SelectComponent from '../../../../components/SelectComponent/SelectComponent';
import { gtmNewsletterSubscription } from '../../../../gtm/events';
import useAxios from '../../../../hooks/useAxios/useAxios';
import useMessage from '../../../../hooks/useMessage/useMessage';
import { useScreenDetector } from '../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

const Newsletter = () => {
  const axios = useAxios();
  const [Message, showMessage, closeMessage] = useMessage();
  const {
    constants: {
      prefixOptions,
      formRules: { emailRule },
    },
  } = useTranslate();

  const { isDesktop } = useScreenDetector();

  const {
    control,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
  });

  const handleSubmitForm = async ({
    title,
    firstNameNewsletter,
    lastNameNewsletter,
    emailNewsletter,
  }) => {
    console.log(
      title,
      firstNameNewsletter,
      lastNameNewsletter,
      emailNewsletter
    );

    try {
      closeMessage();
      const { data } = await axios.post('/newsletter-sign-up', {
        title,
        firstName: firstNameNewsletter,
        lastName: lastNameNewsletter,
        email: emailNewsletter,
      });

      showMessage(data.message, 'success');
      gtmNewsletterSubscription('Footer');
    } catch (e) {
      const message =
        e.response?.data?.message || 'Something went wrong, Please try later.';
      showMessage(message, 'danger');
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} noValidate>
      <div className="my-4">
        <Row>
          <Col md="4">
            <SelectComponent
              name="title"
              control={control}
              label="Title"
              setValue={setValue}
              options={prefixOptions}
              watch={watch}
              errors={errors}
              autoComplete="honorific-prefix"
            />
          </Col>
          <Col md="4">
            <InputComponent
              name="firstNameNewsletter"
              control={control}
              rules={{ required: 'First name is required' }}
              errors={errors}
              label={<>First Name</>}
              autoComplete="given-name"
              placeholder=" "
            />
          </Col>
          <Col md="4">
            <InputComponent
              name="lastNameNewsletter"
              control={control}
              rules={{ required: 'Last name is required' }}
              errors={errors}
              label={<>Last Name</>}
              autoComplete="last-name"
              placeholder=" "
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputComponent
              name="emailNewsletter"
              control={control}
              rules={emailRule}
              errors={errors}
              passivePlaceholder="alex@example.com"
              label={<>Email address</>}
              autoComplete="email"
            />
          </Col>
        </Row>
      </div>
      <div style={{ fontSize: '10px', lineHeight: '18px' }}>
        I would like to be kept updated on exclusive news, traven inspiration
        and offers from the legendary world of Belmond, and recieve tailored
        communications based on my interests and circumstances. To opt out, use
        the unsubscribe link or email{' '}
        <span style={{ textDecoration: 'underline', fontSize: '10px' }}>
          unsubscribe@belmond.com
        </span>
      </div>
      <div>
        <div
          className={`d-flex ${
            isDesktop ? 'flex-row' : 'flex-column'
          } gap-3 mt-3 gap-2`}
        >
          <LoadingButton
            type="submit"
            className="button"
            loading={isSubmitting}
            ariaLabel="Sign up"
          >
            SIGN UP
          </LoadingButton>

          <Message />
        </div>
      </div>
    </Form>
  );
};

export default Newsletter;
